import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';

export default function InsightsPage({ pageContext }) {
    const { data } = pageContext;

    return (
        <Layout>
            <Seo
                title="Insights"
                description="Introducing you to our Customer Success Stories."
            />
            <div className="inttop"></div>
            <div className="app-title insight-title">
                <div className="container">
                    <div className="titlefull">
                        <div className="titlefull__txt">
                            <h1>Insights</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content featurebg">
                <div className="container">
                    {data && data !== undefined
                        ? data.map((a) => (
                              <div
                                  className="insight"
                                  key={`insight-${a.visnsId}`}
                              >
                                  <div className="insight__left">
                                      <Link to={`/post/${a.slug}`}>
                                          <h1>{a.title}</h1>
                                      </Link>
                                      <p>{a.summary}</p>
                                      <Link
                                          className="btn"
                                          to={`/post/${a.slug}`}
                                      >
                                          Read More
                                      </Link>
                                  </div>
                                  <div className="insight__right">
                                      {a.localFile &&
                                      a.localFile.childImageSharp ? (
                                          <>
                                              <Image
                                                  src=""
                                                  dynamic={
                                                      a.localFile
                                                          .childImageSharp
                                                          .gatsbyImageData
                                                  }
                                                  alt={data.title}
                                              />
                                          </>
                                      ) : (
                                          <Image src="insight-ph.png" alt="" />
                                      )}
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </Layout>
    );
}
